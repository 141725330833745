var BrowserData = {};
function myfunc(data){
    console.log(data);
}
var BrowserInfo = new Class({
    options:{
        'safariTest': new RegExp(/safari/ig),
        'chromeTest': new RegExp(/chrome/ig),
        'firefoxTest': new RegExp(/firefox/ig),
        'internetExplorerTest': new RegExp(/MSIE/ig),
        'internetExplorerNewTest': new RegExp(/microsoft/ig),
        'mobileTest': new RegExp(/mobile/ig),
        'ipadTest': new RegExp(/ipad/ig),
        'iphoneTest': new RegExp(/iphone/ig),
        'androidTest': new RegExp(/android/ig),
        'isWindows': new RegExp(/windows/ig),
        'isApple': new RegExp(/macintosh/ig)
    },
    initialize: function(userAgent){
        var isSafari    = (this.options.safariTest.test(userAgent) && !this.options.chromeTest.test(userAgent)),
            isChrome    = (this.options.chromeTest.test(userAgent)),
            isFirefox   = (this.options.firefoxTest.test(userAgent)),
            isIE        = (this.options.internetExplorerTest.test(userAgent)),
            isIE_new    = (this.options.internetExplorerNewTest.test(userAgent)),

            isMobile    = (this.options.mobileTest.test(userAgent)),
            isIPad      = (this.options.ipadTest.test(userAgent)),
            isIPhone    = (this.options.iphoneTest.test(userAgent)),
            isAndroid   = (this.options.androidTest.test(userAgent)),

            isWindows   = (this.options.isWindows.test(userAgent)),
            isMac = (this.options.isApple.test(userAgent));

        // browser
        if(isSafari){
            BrowserData.Name = 'Safari';
        }else if(isChrome){
            BrowswerData.Name = 'Chrome';
        }else if(isFirefox){
            BrowserData.name = 'Firefox';
        }else if(isIE || isIE_new){
            BrowserData.Name = 'IE';
        }else{
            BrowserData.Name = 'other';
        }

        // mobile or not
        if(isMobile){
            BrowserData.isMobile = true;
        }else{
            BrowserData.isMobile = false;
        }

        // device
        if(isIPad){
            BrowserData.Device = 'iPad';
        }else if(isIPhone){
            BrowserData.Device = 'iPhone';
        }else if(isAndroid){
            BrowserData.Device = 'Android';
        }else{
            if(isMobile){
                BrowserData.Device = 'Handheld';
            }else{
                BrowserData.Device = 'Desktop';
            }
        }

        // screen mode
        if(window.getSize().y > window.getSize().x){
            if(isMobile){
                BrowserData.Orientation = 'Handheld Portrait';
            }else{
                BrowserData.Orientation = 'Desktop Standing';
            }
        }else{
            if(isMobile){
                BrowserData.Orientation = 'Handheld Landscape';
            }else{
                BrowserData.Orientation = 'Desktop Laying';
            }
        }

        // platform
        if(isWindows){
            BrowserData.Platform = 'Windows';
        }else{
            if(['iPad','iPhone'].indexOf(BrowserData.Device) >= 0){
                var match = userAgent.match(/OS\s{1}(\d+_\d+)/g)
                if (match) {
                    var version = match[0].replace('OS ','').replace('_','.');
                    BrowserData.Platform = 'iOS ' + version;
                }
            }else{
                if(isMac){
                    BrowserData.Platform = 'MacOS';
                }else{
                    BrowserData.Platform = 'Other';
                }
            }
        }

        // Brand
        if(['iOS','MacOS'].indexOf(BrowserData.Platform) >= 0 || ['iPhone','iPad'].indexOf(BrowserData.Device) >= 0){
            BrowserData.Brand = 'Apple';
        }else{
            if(isMobile){
                BrowserData.Brand = 'Unknown handheld manufacturer';
            }else{
                BrowserData.Brand = 'Unknown PC manufacturer';
            }
        }
    }
});
new BrowserInfo(navigator.userAgent);
