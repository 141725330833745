var Menu = {
    show:function(){
        document.body.addClass('showMenu');
        try{
            if(typeOf(quriobot) == 'object'){
                quriobot && quriobot.preventAutoStart();
            }
        }catch(e){console.log(e)}
    },
    hide:function(){
        document.body.removeClass('showMenu');
        try{
            if(typeOf(quriobot) == 'object'){
                quriobot && quriobot.allowAutoStart();
            }
        }catch(e){console.log(e)}
    },
    open:function(oM){
        if(oM){
            oMcontent = $$('.menu--mainContent');
            oMcontent.each(function(oMc,i){
                Menu.shut(oMc);
            });
            if(oM.getElements('.menu--subWrapper').length){
                var oWrapper = oM.getElements('.menu--subWrapper')[0],
                    oContent = oM.getElements('.menu--subContent')[0];

                oWrapper.setStyles({
                    'width' : oContent.getSize().x
                });
            }
            oM.addClass('open');
        }
    },
    shut:function(oM){
        if(oM){
            if(oM.getElements('.menu--subWrapper').length){
                var oWrapper = oM.getElements('.menu--subWrapper')[0];

                oWrapper.setStyles({
                    'width' : 0
                });

            }
            oM.removeClass('open');
            oM.getElements('.menu--subItem').removeClass('open');
        }
    },
    attach:function(){
        oMcontent = $$('.menu--mainContent');
        oMcontent.each(function(oM,i){
            // oM.getElements('.menu--mainItem')[0].addEvents({
            //     click:function(){
            //         Menu.hide();
            //         if(!oM.hasClass('open')){
            //             Menu.open(oM);
            //             if(!this.hasClass('noContent')){
            //                 Page.open()
            //             }else{
            //                 Page.shut(this.hasClass('noContent'));
            //             };
            //         }else{
            //             Menu.shut(oM);
            //             if(!this.hasClass('noContent')){Page.shut();}
            //         }
            //     }
            // });
            oM.getElements('.menu--subItem').each(function(oSub,i){
                var url = oSub.get('href');

                oM.getElements('.menu--subItem.open').removeClass('open');
                if(window.location.href.indexOf(url) !== -1) {
                    oSub.getParent().addClass('open');
                }

                // oSub.addEvents({
                //     click:function(e){
                //         if(!oSub.get('href')){
                //             if(e){
                //                 e.preventDefault();
                //             }
                //             Menu.hide();
                //             oM.getElements('.menu--subItem.open').removeClass('open');
                //             oSub.addClass('open');
                //             Page.evaluate();
                //         }
                //     }
                // })
            });
        });
        $$('a.js--trigger').each(function(jsObj, i){
            if (jsObj) {
              jsObj.addEvents({
                  click:function(e){
                      if(jsObj.get('href')){
                          return;
                      }
                      if(e){
                          e.preventDefault();
                      }
                      var triggerRelated = jsObj.get('data-href');
                      if(jsObj.get('href')){
                          var newLocation = jsObj.get('href') + '#' + (jsObj.get('data-href').replace('#',''));
                          location.href = newLocation;
                      }else{
                          Page.gotoSection( jsObj.get('data-href').replace('#','') );
                      }
                  }
              })
            }
        });
        $('menuIcon').addEvents({
            click:function(){
                if(document.body.hasClass('showMenu')){
                    Menu.hide();
                }else{
                    Menu.show();
                }
            }
        });
    },
    init:function(){
        if($$('nav').length && $$('nav')[0].getElements('ul').length){
            Menu.attach();
        }
    }
},
Page = {
    evaluate:function(){


        if($$('.menu--mainContent.open').length){
            var OpenMenuItem = $$('.menu--mainContent.open')[0];
        }

        if(OpenMenuItem){
            if(OpenMenuItem.getElements('.menu--subItem.open').length){
                var OpenSection = OpenMenuItem.getElements('.menu--subItem.open')[0];
            }

            if(!OpenSection){
                OpenSection = OpenMenuItem.getElements('.menu--subItem')[0];
                OpenSection.addClass('open');
            }

            var OpenSection_name = OpenSection.get('data-href');
            Page.activate(OpenSection_name);
        }
    },
    deactivate:function(oSection){
        var oWrapper = oSection.getElements('.section--wrapper')[0];
        if (oWrapper) {
            oWrapper.removeEvents('scroll');
        }
        oSection.removeClass('active');
    },
    activate:function(sectionName){
        $$('section').each(function(oSection,i){
            Page.deactivate(oSection);
        });
        var active_section = $$('section[name=' + sectionName + ']')[0];
        if(!active_section){
            return;
        }
            active_section.addClass('active');

        var oWrapper = active_section.getElements('.section--wrapper')[0];
            oWrapper.scrollTo(0,0);
        Page.track(oWrapper);
        Page.anchorLink(oWrapper);
    },
    switch:function(oItems){
        var currentActive = oItems[oItems.length  - 1];
        if(!currentActive){
            return;
        }
        var currentAnchor = '#' + currentActive.get('id');
            currentAnchor = currentActive.getParent('section').getElements('a[href=' + currentAnchor + ']')[0];

        currentAnchor.getSiblings('a').removeClass('current');
        currentAnchor.addClass('current');
    },
    track:function(oWrapper){
        var MenuItems = oWrapper.getElements('.section--item');
        var PageItems = [];

        MenuItems.each(function(obj,i){
            PageItems.push(oWrapper.getElements('[id=' + obj.get('href').replace('#','') + ']')[0]);
        });

        var InViewItems = [];
        oWrapper.addEvent('scroll',function(){
            var ScrolledDistance = oWrapper.getScroll().y;
            var PageSize = oWrapper.getSize().y
            PageItems.each(function(oPart,i){
                var pp = (oPart.getPosition().y + ScrolledDistance);
                var bb = (BrowserData.isMobile)? 1 : 0.33;
                if(pp - (ScrolledDistance + (PageSize * bb)) <= 0){
                    if(InViewItems.indexOf(oPart) < 0){
                        InViewItems.push(oPart);
                        Page.switch(InViewItems);
                    }
                }else{
                    if(InViewItems.indexOf(oPart) >= 0){
                        InViewItems.pop(oPart);
                        Page.switch(InViewItems);
                    }
                }
            });
        });

    },
    anchorLink:function(pageSection){
        var body = $$('.section--wrapper')[0];

        if(body){
            var scroll = new Fx.Scroll(body, {
                wait: false,
                wheelStops: false,
                duration: 1000,
                offset: {'x': 0, 'y': -100},
                transition: Fx.Transitions.Quad.easeInOut
            });

            if(pageSection){
                scroll.toElement(pageSection);
            }

            $$('.anchor-link').addEvent('click', function(){
                var thisAnchor = this.get('href').substr(1);

                scroll.toElement(thisAnchor);
            })
        }
    },
    collapsible:function(){
        window.addEvent('domready', function(){
            new Fx.Accordion($('.collapsible'), '.collapsible__title', '.collapsible__content',{
                display: -1,
                keepOpen: true,
                onActive: function(toggler, element){
                    toggler.addClass('collapsible__title_is-expanded');
                },
                onBackground: function(toggler, element){
                    toggler.removeClass('collapsible__title_is-expanded');
                },
            });
        });
    },
    shut:function(pageOnly){
        if($('neverShut')){
            return;
        }
        $$('section').each(function(oSection,i){
            Page.deactivate(oSection);
        });
        document.body.removeClass('main--open');
        if(!pageOnly){
            Menu.shut($$('.menu--mainContent.open')[0]);
            Page.accomodate('main',true);
            try{
                if(typeOf(quriobot) == 'object'){
                    quriobot.allowAutoStart();
                }
            }catch(e){return}
        }
    },
    open:function(){
        document.body.addClass('main--open');
        Page.evaluate();
        Page.accomodate('main',true);
        Page.accomodate('main');
        if(typeOf(quriobot) == 'object'){
            quriobot.preventAutoStart();
        }
    },
    off:true,
    attach:function(){
        $$('.close--main').each(function(oClose,i){
            oClose.addEvent('click',function(){
                Page.shut();
            });
        });
        $$('.section--item').each(function(oItem){
            oItem.addEvent('click',function(e){
                if(e){
                    e.preventDefault();
                }
                oItem.getSiblings('.section--item').removeClass('current');
                oItem.addClass('current');

                var oAnchor = oItem.get('href');
                var oParent = oItem.getParent('section');
                var oWrapper = oParent.getElements('.section--wrapper')[0];

                var Destination = oWrapper.getElements('[id=' + oAnchor.replace('#','') + ']')[0];
                    Destination_loc = (Destination.getPosition().y + oWrapper.getScroll().y) - 184;

                var scrollToWrapper = new Fx.Scroll(oWrapper, { fps: 30, wheelStops: false}).start(0, Destination_loc)

            });
        });
        $$('.back-to-top').each(function(obj,i){
            obj.addEvent('click',function(){
                var scrollToWrapper = new Fx.Scroll(obj.getParents('.section--wrapper')[0], {
                  fps: 30,
                  wheelStops: false,
                }).start(0, 0)
            });
        });
        $$('main,nav ul,.read-more').addEvents({
            mouseenter:function(){
                Page.off = false;
            },
            mouseleave:function(){
                Page.off = true;
            }
        });
        window.addEvents({
            click:function(){
                if(Page.off){
                    Page.shut();
                }
            }
        });
    },
    gotoSection:function(PageActive){
        var ActiveSection = null;
        if(!$(PageActive)){
            if($$('section[name=' + PageActive +']').length){
                var PageSection = $$('section[name=' + PageActive +']')[0];
                if(PageSection.getElements('.section--item').length){
                    var FirstItem = PageSection.getElements('.section--item')[0].get('href').replace('#','');
                    if($(FirstItem)){
                        PageActive = FirstItem;
                    }else{
                        return;
                    }
                }else{
                    ActiveSection = PageSection;
                }
            }

        }

        var ActiveElement = ($(PageActive))? $(PageActive) : null;
            ActiveSection = (ActiveSection !== null)? ActiveSection : ActiveElement.getParent('section');
        var LinkedMenuItem = $$('a[data-href=' + ActiveSection.get('name') + ']')[0];
        var LinkedSubItem = $$('a[data-href=' + PageActive + ']')[0];
        var LinkedMenuWrap = LinkedMenuItem.getParent('.menu--mainContent');
        var LinkedMenu = LinkedMenuWrap.getElements('a.menu--mainItem')[0];


        if(!LinkedMenuWrap.hasClass('open')){
            if(LinkedMenuItem != LinkedMenu){
                LinkedMenu.fireEvent('click');
            }
        }
        if(LinkedMenuItem){
            LinkedMenuItem.fireEvent('click');
        }
        if(LinkedSubItem){
            LinkedSubItem.fireEvent('click');
        }
    },
    onload:function(){
        var PageLocation = location.href;
        if(PageLocation.indexOf('#') >= 0){
            var PageActive = PageLocation.split('#')[1];
            this.anchorLink(PageActive);
        }
    },
    accomodate:function(element,reset){
        if(!$('botwrapper')){
            return;
        }
        if(!$$('main').length){
            return;
        }
        if(reset){
            $$('main')[0].removeProperty('style');
            $('botwrapper').removeProperty('style');
            return;
        }
        var panelSize = $$('main')[0].getSize().x;
        var botSize = $('botwrapper').getSize().x;
        var windowSize = document.body.getSize().x;
        var accoSize;

        switch(element){
            case "bot":
                if(document.body.hasClass('main--open')){
                    if((panelSize + botSize) > windowSize){
                        accoSize = (windowSize - (panelSize + botSize));
                        $$('main').setStyles({
                            'transform':'translate3d('+ accoSize +'px,0px,0)'
                        });
                    }
                }
                break;
            case "main":
                if($('botwrapper').hasClass('show')){
                    if((panelSize + botSize) > windowSize){
                        accoSize = ((panelSize + botSize) - windowSize);
                        $('botwrapper').setStyles({
                            'transform':'translate3d('+ accoSize +'px,0px,0)'
                        });
                    }
                }
                break;
        }
    },
    pretty:function(){
        $$('.section--filler').each(function(oFiller,i){
            var Section = oFiller.getParents('section')[0];
            var SectionBody = oFiller.getParents('.section--body')[0];

                //flash show ;)
                Section.setStyles({'display':'block','opacity':'0'});

            var innerElements = SectionBody.getElements('div,p,ul,ol,h1,h2,h3,h4,h5');
            var outerHeight = SectionBody.getSize().y;
            var outerPadding = SectionBody.getStyle('padding-top').toInt() + SectionBody.getStyle('padding-bottom').toInt();
            var innerHeight = -20;
                innerElements.each(function(oElement){
                    if(['ul','ol'].indexOf(oElement.getParent().get('tag').toLowerCase()) < 0){
                        innerHeight += oElement.getSize().y;
                        //innerHeight += (oElement.getStyle('margin-top').toInt() + oElement.getStyle('margin-bottom').toInt());
                    }
                });
            if(innerHeight < outerHeight){
                var fillerHeight = (outerHeight - outerPadding) - innerHeight;
                oFiller.setStyles({'height':fillerHeight});
            }

            Section.erase('style');
        });
    },
    init:function(){
        if($$('main').length){
            Page.attach();
            Page.onload();
            Page.pretty();
            Page.anchorLink();
            Page.collapsible();
        }
    },
},
Bots = {
    attach:function(){
        $$('.use-bot').each(function(oUse,i){
            oUse.addEvent('click',function(){
                var botData = oUse.get('data-use');
                if(oUse.hasClass('bot--active')){
                    if(quriobot && quriobot.hide_frame){
                        quriobot.hide_frame();
                    }
                }else{
                    if(quriobot && quriobot.start){
                        if(quriobot && quriobot.init && !quriobot.inited){
                            quriobot.init({
                                use: botData,
                                onInit: function(){
                                    quriobot.start(botData);
                                }
                            })
                        } else {
                            quriobot.start(botData);
                        }
                        Bots.show();
                    }
                }
            });
        });
    },
    show:function(){
        var oUse = $$('[data-use=' + quriobot && quriobot.get('activebot') + ']');
            oUse = (oUse.length)? oUse[0] : false;

        if(oUse){
            oUse.addClass('bot--active');

            var botIcon = oUse.getElements('span')[0];
            var botEnd_y = document.body.getSize().y * .60,
                botCur_y = botIcon.getPosition().y,
                botDiff_y = (botEnd_y - botCur_y) * .85;

            var botEnd_x = document.body.getSize().x - (360 - 32);
                botCur_x = botIcon.getPosition().x,
                botDiff_x = (botEnd_x - botCur_x) * .85;

                botIcon.setStyles({
                    'transition': 'all .3s ease',
                    'transform':'translate3d('+botDiff_x+'px,'+botDiff_y+'px,0) scale(.5)',
                    'opacity':0,
                });
        }
        Page.accomodate('bot');
    },
    hide:function(){
        var buttons = $$('.use-bot.bot--active');
            buttons.removeClass('bot--active');

        if(buttons.length){
            var icon = buttons[0].getElements('span')[0];
                icon.removeProperty('style');
        }
        Page.accomodate('bot',true);
    },
    init:function(){
        Bots.attach();
    }.bind(this)
};

/**
 * Cross-browser on page ready
 * @param  {Function} fn callback function
 */
var ready = function (fn) {
  if (window.document.readyState === 'complete') {
      return fn();
  }

  if (window.addEventListener) {
      window.addEventListener('load', fn, false);
  }
  else if (window.attachEvent) {
      window.attachEvent('onload', fn);
  }
  else {
      window.onload = fn;
  }
};

ready(
  function(){
      Menu.init();
      Page.init();
      Bots.init();
      if($('content-preloader')){
          $('content-preloader').removeClass('loading');
      }
      if($('wallpaper')){
          $('wallpaper').removeClass('loading');
      }
})
